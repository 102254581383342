/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledTooltip,
  UncontrolledDropdown,
  Nav,
  Badge,
} from "reactstrap";
import { connect } from "react-redux";
import { logout } from "../../redux/actions/authAction";
import PropTypes from "prop-types";

import {
  // AppAsideToggler,
  AppNavbarBrand,
  AppSidebarToggler,
} from "@coreui/react";

// Components
import Notifications from "../../Components/Notifications";

// Assets and Styles
import avatar from "../../assets/img/avatars/male.jpg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logoTop from "../../assets/img/logo/logo.png";
import backbutton from "../../assets/img/icons/backbutton.png";

// User Actions
import { getCitasEnEsperaUrl, userInfo } from "../../redux/actions/types";
import {
  getUserInfo,
  getUserApi,
  getUserNotification,
} from "../../redux/actions/userAction";

import { getDetalleCarroComprasUrl } from "../../redux/actions/types";
import { getUserSetAppointment } from "../../redux/actions/userAction";
import {
  GetCitaEnEspera,
  GetDetalleCarroCompras,
} from "../../redux/actions/DoctorAction";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import axios from "axios";
import Swal from "sweetalert2";
import ManitoLogo from "../../assets/img/manito.jpeg";
import {
  pusherAppCluster,
  pusherAppKey,
  pusherHost,
  pusherPort,
} from "../../enviroment";

// Material Components
import withWidth from "@material-ui/core/withWidth";

// Styles
import styles from "./Styles";
import { propTypes } from "react-recaptcha";
import { TobarContainer, LogoTopBarContainer } from "./defaultHeader.style";

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canasta: <li id="menuCarroCompras"></li>,
      userInfo: null,
    };
    this.pintarCanasta = this.pintarCanasta.bind(this);
  }
  componentWillMount() {
    this.props.getUserInfo(userInfo, true).then((res) => {
      if (res?.data) {
        this.props.getUserApi(`api/v1/users/${res.data?.id}`);
        this.setState({ userInfo: res.data });
      }
    });
  }

  //#region Functions
  onLogoutClick(e) {
    Swal.fire({
      title: "Cerrar Sesión",
      text: "Esta seguro que desea cerrar sesión?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        this.props.logout(this.props.user.userInfo);

        // this.context.router.history.push('/login');
        setTimeout(() => {
          window.location.reload();
        }, 700);
      }
    });
  }
  pintarCanasta() {
    this.props
      .GetDetalleCarroCompras(getDetalleCarroComprasUrl, {
        action_type: 0,
      })
      .then((resp) => {
        if (resp.code === 1001) {
          //  console.log('0000');
          this.setState({ canasta: <li id="menuCarroCompras"></li> });
          return;
        }
        if (resp.code === 2001) {
          // console.log('1111');
          this.setState({ canasta: <li id="menuCarroCompras"></li> });
          return;
        }

        if (resp.data != null) {
          if (resp.data.length == 0) {
            //    console.log('2222');
            this.setState({ canasta: <li id="menuCarroCompras"></li> });
            this.setState({ estaLogueado: true });
          } else {
            //   console.log('3333');
            this.setState({
              canasta: (
                <li id="menuCarroCompras">
                  <a href="/#/Summary">
                    <i
                      style={{
                        fontSize: 20,
                        color: "#eae4e4",
                        marginLeft: "5px",
                      }}
                      className="fa fa-shopping-basket fa-xs"
                    ></i>
                  </a>{" "}
                  <Badge color="primary">{resp.data.length}</Badge>
                  <UncontrolledTooltip
                    placement="bottom"
                    target="menuCarroCompras"
                  >
                    {" "}
                    Ir al carro de compras
                  </UncontrolledTooltip>
                </li>
              ),
            });
            this.setState({ estaLogueado: true });
          }
        } else {
          this.setState({ canasta: <li id="menuCarroCompras"></li> });
        }
      });
  }

  componentDidMount() {
    this.pintarCanasta();
  }

  goIndex() {
    const profile = this.props.user.userInfo.profile;
    if (profile) {
      if (profile.profile_name == "Paciente") {
        this.context.router.history.push("/Dashboard");
      }
      if (profile.profile_name == "Medico") {
        this.context.router.history.push("/CitasMedico/MisCitas");
      }
    } else {
      this.context.router.history.push("/Index");
    }
  }

  //#region Build Functions
  buildLogoWithBackBtn(icon, size) {
    return (
      <div
        className="pr-2"
        style={{ cursor: "pointer" }}
        id="backContainer"
        onClick={this.goIndex.bind(this)}
      >
        <img
          src="./static/media/logo.2faa15c3.png"
          width="105"
          height="35"
          alt="DoctorHoy"
          style={{ objectFit: "contain" }}
        />
        <UncontrolledTooltip placement="bottom" target="backContainer">
          {`Ir a la página principal`}
        </UncontrolledTooltip>
      </div>
    );
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
    }
    return true;
  }
  render() {
    const containerStyle = {
      zIndex: 1999,
    };

    const userInfo = this.props.user.userInfo;
    const userInfoApi = this.props.user.userInfoApi;

    const handleEditProfile = () => {
      if (userInfo?.type_user === "0") {
        this.context.router.history.push("/Patient/EditProfilePatient");
      } else {
        this.context.router.history.push("/Doctor/EditDoctor");
      }
    };

    // const customAlert = (notification) => {
    //   console.log(notification);
    //   Swal.fire({
    //     title: notification.title,
    //     // text: notification.message,
    //     html: notification.message,
    //     imageUrl: notification.icon ? notification.icon : ManitoLogo,
    //     imageWidth: 200,
    //     imageHeight: 200,
    //   });
    // };

    // window.Echo = new Echo({
    //   broadcaster: "pusher",
    //   cluster: pusherAppCluster,
    //   encrypted: true,
    //   key: pusherAppKey,
    //   wsHost: pusherHost,
    //   wsPort: pusherPort,
    //   forceTLS: false,
    //   disableStats: true,
    // });

    // window.Echo.channel(
    //   `notifications.${userInfo?.id?.toUpperCase()}`
    // ).notification((notification) => {
    //   console.log("Tipo de notificación", notification);
    //   const commonNotificationsTypes = [
    //     "offer.expired",
    //     "cancel.order.due.expiration",
    //     "appointment.rejected",
    //     "patient-appointment.rescheduled",
    //     "patient-offer.expired",
    //     "video-consultation.finished",
    //     "patient-medical-history-authorization.requested",
    //     "pending-appointment.unanswered",
    //     "customer-service-pending-appointment.unanswered",
    //     "video-consultation.started",
    //     "patient-exam.loaded",
    //     "review-exam-care.evaluated",
    //   ];

    //   if (commonNotificationsTypes.includes(notification.type)) {
    //     customAlert(notification);
    //   }

    //   if (notification.type === "video-consultation.started") {
    //     Swal.fire({
    //       title: notification.title,
    //       // text: notification.message,
    //       html: notification.message,
    //       imageUrl: notification.icon ? notification.icon : ManitoLogo,
    //       imageWidth: 200,
    //       imageHeight: 200,
    //     }).then(() => {
    //       window.location.reload();
    //     });
    //   }

    //   if (notification.type === "appointment.accepted") {
    //     Swal.fire({
    //       title: notification.title,
    //       // text: notification.message,
    //       html: notification.message,
    //       imageUrl: notification.icon ? notification.icon : ManitoLogo,
    //       imageWidth: 200,
    //       imageHeight: 200,
    //     }).then((result) => {
    //       if (result.isConfirmed) {
    //         if (window.location.href.includes("CitasMedico") || window.location.href.includes("/InterConsultas")) {
    //           window.location.reload();
    //         }
    //       } else {
    //         if (window.location.href.includes("CitasMedico") || window.location.href.includes("/InterConsultas")) {
    //           window.location.reload();
    //         }
    //       }
    //     });
    //   }
    //   if (notification.type === "appointment.offered") {
    //     Swal.fire({
    //       title: notification.title,
    //       // text: notification.message,
    //       html: notification.message,
    //       imageUrl: notification.icon ? notification.icon : ManitoLogo,
    //       imageWidth: 200,
    //       imageHeight: 200,
    //     }).then((result) => {
    //       if (result.isConfirmed) {
    //         if (window.location.href.includes("/Dashboard") || window.location.href.includes("/InterConsultas")) {
    //           window.location.reload();
    //         }
    //       } else {
    //         if (window.location.href.includes("/Dashboard") || window.location.href.includes("/InterConsultas")) {
    //           window.location.reload();
    //         }
    //       }
    //     });
    //   }
    //   if (notification.type === "pending-appointment.created") {
    //     Swal.fire({
    //       title: notification.title,
    //       // text: notification.message,
    //       html: notification.message,
    //       imageUrl: notification.icon ? notification.icon : ManitoLogo,
    //       imageWidth: 200,
    //       imageHeight: 200,
    //     }).then((result) => {
    //       if (result.isConfirmed) {
    //         if (window.location.href.includes("consultasSinOfertas")) {
    //           window.location.reload();
    //         }
    //       } else {
    //         if (window.location.href.includes("consultasSinOfertas")) {
    //           window.location.reload();
    //         }
    //       }
    //     });
    //   }
    //   this.props.getUserNotification(userInfo?.id);
    // });

    return (
      <React.Fragment>
        <LogoTopBarContainer>
          <img src={logoTop} alt="Logo" />
          {this.state.userInfo && (
            <span>Hola, {this.state.userInfo.full_name}</span>
          )}
        </LogoTopBarContainer>

        <Nav className="ml-auto mr-3 h-100" navbar>
          <TobarContainer>
            <div className="arrow-back-container">
              <i
                className="fa fa-arrow-left"
                onClick={() => {
                  window.history.back();
                }}
                aria-hidden="true"
              ></i>
            </div>
            <div className="notification-bell-container">
              <Notifications />
            </div>
            {!this.state.canasta ? "" : this.state.canasta}

            {userInfoApi && userInfoApi?.photo?.length > 50 ? (
              <div className="account-container" onClick={handleEditProfile}>
                <img
                  src={`data:image/png;base64, ${userInfoApi?.photo}`}
                  width="28"
                  className="circle"
                />
                <span>Mi Cuenta</span>
              </div>
            ) : (
              <div className="account-container" onClick={handleEditProfile}>
                <i className="fa fa-user-circle-o" aria-hidden="true" />
                <span>Mi Cuenta</span>
              </div>
            )}

            <div
              onClick={this.onLogoutClick.bind(this)}
              className="logout-container"
            >
              <i className="fa fa-sign-in" aria-hidden="true" />
              <span>Cerrar Sesión</span>
            </div>
          </TobarContainer>
        </Nav>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          style={containerStyle}
        />
      </React.Fragment>
    );
  }
}

DefaultHeader.defaultProps = {};

DefaultHeader.propTypes = {
  logout: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  getUserApi: PropTypes.func.isRequired,
  GetDetalleCarroCompras: PropTypes.func.isRequired,
  user: PropTypes.object,
  width: "xs" | "sm" | "md" | "lg" | "xl",
  children: PropTypes.node,
};

DefaultHeader.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.user,
  errorCode: state.user.errorCode,
  getUserNotification: propTypes.func,
  GetCitaEnEspera: propTypes.func,
  getUserSetAppointment: propTypes.func,
});

export default connect(mapStateToProps, {
  logout,
  getUserInfo,
  getUserApi,
  GetDetalleCarroCompras,
  getUserNotification,
  GetCitaEnEspera,
  getUserSetAppointment,
})(withWidth()(DefaultHeader));
