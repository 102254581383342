/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { Button, FormGroup, Col, Card, CardBody } from "reactstrap";
import { toast } from "react-toastify";

// Components
import Footer from "../../Components/Templates/Footer";
import Header from "../../Components/Templates/Header";
import RefreshToken from "../../views/Generic/RefreshToken";
import SummaryTable from "./SummaryTable";
import ConsInformado from "../ConsentimientoInformado/ConsInformadoPDF";

// Redux
import {
  getDatosCompraUrl,
  getDetalleCarroComprasUrl,
} from "../../redux/actions/types";
import { GetDatosCompra } from "../../redux/actions/DoctorAction";
import {
  GetDetalleCarroCompras,
  GetDetalleOrdenDeComprasLaravel,
  deleteOrdenCompraLaravel,
} from "../../redux/actions/DoctorAction";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

// Utils
import {
  SummaryTermsConditions,
  payuAction,
  payuConfirmation,
  payuResponse,
  payuAccountId,
  payuMerchantId,
} from "../../enviroment";
import { consApi } from "../../Components/MessageBirdApi/MessageBirdApi";

// Styles
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "react-sticky-header/styles.css";
import "react-toastify/dist/ReactToastify.css";

import "../Index/css/bootstrap.min.css";
import "../Index/css/style1.css";
import "../Index/css/menu.css";
import "../Index/css/vendors.css";
import "../Index/css/custom.css";
import Swal from "sweetalert2";

class Summary extends Component {
  /*class Index extends Component {*/
  myRef = React.createRef();

  callInterval = null;

  constructor(props) {
    super(props);
    this.state = {
      objectId: "",
      estadoModal: false,
      productos: [],
      merchantId: "",
      referenceCode: "",
      amount: "",
      currency: "",
      signature: "",
      url: "",
      activarPago: false,
      valor: "",
      numeroItems: 0,
      carroId: 0,
      terminosAceptados: false,
      consInformado: false,
      displayCarroVacio: "none",
      displayCarroActivo: "none",
      accountId: "",
      datosCompraLaravel: null,
    };

    this.options = {
      sortIndicator: false,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true,
      alwaysShowAllBtns: false,
      withFirstAndLast: false,
      noDataText: "No se ha encontrado",
    };

    this.cellButtons = this.cellButtons.bind(this);
    this.eliminarCita = this.eliminarCita.bind(this);
    this.obtenerDatos = this.obtenerDatos.bind(this);
    this.cambiaEstado = this.cambiaEstado.bind(this);
    this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
    this.handleChangeCheckBoxConsInformado =
      this.handleChangeCheckBoxConsInformado.bind(this);
    this.abrirTerminos = this.abrirTerminos.bind(this);
  }

  componentDidMount() {
    this.startInterval();
    this.obtenerDatos();
  }

  componentWillMount() {
    const userDoctorId = localStorage.getItem("DoctorId");
    const userPatientId = localStorage.getItem("idUser1");

    const userId = userPatientId ? userPatientId : userDoctorId;

    this.props
      .GetDetalleOrdenDeComprasLaravel(userId)
      .then((res) => {
        this.setState({ datosCompraLaravel: res });
        //
        if (res && res.length > 0) {
          const data = res[0];
          const orderDetails = res[0]?.purchase_order_details[0];
          const settings = res[0]?.system_settings;
          //
          this.setState({ merchantId: settings.merchant_id });
          this.setState({ referenceCode: data.reference_code });
          this.setState({ amount: data.amount });
          this.setState({ currency: data.currency });
          this.setState({ signature: data.signature });
          this.setState({ url: settings.url });
          this.setState({ carroId: data.payment_reference });
          this.setState({ valor: data.amount });
          this.setState({ accountId: settings.account_id });

          this.setState({ numeroItems: data.purchase_order_details.length });
          this.setState({ displayCarroActivo: "" });
          this.setState({ displayCarroVacio: "none" });
        } else {
          this.setState({ displayCarroActivo: "none" });
          this.setState({ displayCarroVacio: "" });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // this.props
    //   .GetDetalleCarroCompras(getDetalleCarroComprasUrl, {
    //     action_type: 0,
    //     //verificar en pruebas si falta mas datos
    //   })
    //   .then((resp) => {
    //     this.setState({ sendRequest: false });
    //     if (resp.code === 1001) this.RefreshToken.toggle();
    //     if (resp.code === 2001) {
    //       this.context.router.history.push("/login?redirect=1");
    //     }
    //     this.setState({ productos: resp.data });
    //     if (resp.data != null) {
    //       this.setState({ numeroItems: resp.data.length });
    //     } else {
    //       this.setState({ numeroItems: 0 });
    //     }
    //     this.setState({ sendRequest: false });
    //   });
  }

  componentWillUnmount() {
    clearInterval(this.callInterval);
  }

  startInterval() {
    const minutes = 15;
    this.callInterval = setTimeout(() => {
      let nameUser = localStorage.getItem("name").replace(/['"]+/g, "");
      const body = {
        username: nameUser,
        cellphone: localStorage.getItem("phone").replace(/['"]+/g, "").trim(),
      };
      consApi(body, "/callvoice")
        .then(() => {
          // clearInterval(this.callInterval);
          // this.startInterval();
        })
        .catch((errorMsg) => {
          return toast.error(`Message bird Error => ${errorMsg}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }, minutes * 60 * 1000);
  }

  obtenerDatos() {
    const userDoctorId = localStorage.getItem("DoctorId");
    const userPatientId = localStorage.getItem("idUser1");

    const userId = userPatientId ? userPatientId : userDoctorId;
    this.props
      .GetDetalleOrdenDeComprasLaravel(userId)
      .then((res) => {
        this.setState({ datosCompraLaravel: res });
        //
        if (res && res.length > 0) {
          const data = res[0];
          const orderDetails = res[0]?.purchase_order_details[0];
          const settings = res[0]?.system_settings;
          //
          this.setState({ merchantId: settings.merchant_id });
          this.setState({ referenceCode: data.reference_code });
          this.setState({ amount: data.amount });
          this.setState({ currency: data.currency });
          this.setState({ signature: data.signature });
          this.setState({ url: settings.url });
          this.setState({ carroId: data.payment_reference });
          this.setState({ valor: data.amount });
          this.setState({ accountId: settings.account_id });

          this.setState({ numeroItems: data.purchase_order_details.length });
          this.setState({ displayCarroActivo: "" });
          this.setState({ displayCarroVacio: "none" });
        } else {
          this.setState({ displayCarroActivo: "none" });
          this.setState({ displayCarroVacio: "" });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // this.props.GetDatosCompra(getDatosCompraUrl, {}).then((resp) => {
    //   var obj = resp.data;
    //   console.log(obj);
    //   this.setState({ merchantId: obj.merchant_id });
    //   this.setState({ referenceCode: obj.reference_code });
    //   this.setState({ amount: obj.amount });
    //   this.setState({ currency: obj.currency });
    //   this.setState({ signature: obj.signature });
    //   this.setState({ url: obj.url });
    //   this.setState({ activarPago: obj.permite_pago });
    //   this.setState({ carroId: obj.carroId });
    //   this.setState({ valor: resp.data.amount });
    //   this.setState({ accountId: obj.idAccount });

    //   if (obj.permite_pago) {
    //     this.setState({ displayCarroVacio: "none" });
    //     this.setState({ displayCarroActivo: "" });
    //   } else {
    //     this.setState({ displayCarroVacio: "" });
    //     this.setState({ displayCarroActivo: "none" });
    //   }
    // });
  }

  cellButtons(cell, row /*enumObject, rowIndex*/) {
    return (
      <Button size="sm" color="danger" tooltip="Eliminar">
        <i
          className="fa icon-trash"
          // onClick={this.eliminarCita.bind(this, row)}
        />
      </Button>
    );
  }

  eliminarCita(orden) {
    Swal.fire({
      title: "¿Esta seguro que desea eliminar la cita de la orden de compra?",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.props.deleteOrdenCompraLaravel(orden.id).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              title: `Eliminada correctamente`,
              icon: `success`,
            }).then(() => {
              this.obtenerDatos();
            });
          }
        });
      }
    });
  }

  cambiaEstado(obj) {
    if (!this.state.terminosAceptados) {
      // no estan aceptados
      obj.preventDefault();
      //aqui una mensaje de que debe aceptar terminos y condiciones
      return toast.error("Debe aceptar terminos y condiciones", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    if (!this.state.consInformado) {
      // no estan aceptados
      obj.preventDefault();
      //aqui una mensaje de que debe aceptar terminos y condiciones
      return toast.error("Debe aceptar el consentimiento informado", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    this.props
      .GetDetalleCarroCompras(getDetalleCarroComprasUrl, {
        action_type: 2,
        id_orden_compra: this.state.carroId,
      })
      .then((resp) => {
        this.setState({ sendRequest: false });
        if (resp.code === 1001) this.RefreshToken.toggle();
        if (resp.code === 2001) {
          obj.preventDefault();
          this.context.router.history.push("/login");
        }
      });
  }

  handleChangeCheckBox() {
    this.setState({ terminosAceptados: !this.state.terminosAceptados });
  }

  handleChangeCheckBoxConsInformado() {
    this.setState({ consInformado: !this.state.consInformado });
  }

  abrirTerminos() {
    console.log("abre terminos");
    this.setState({ estadoModal: true });
  }

  render() {
    const userFullName = localStorage.getItem("full_name");
    const DHusername = localStorage.getItem("DHusername");

    return (
      <div>
        <div>
          <Header
            props={this.props}
            nombreCiudadGps={this.state.nombreCiudadGps}
          />
          <main>
            <div className="content pt-5">
              <div className="container">
                <FormGroup
                  row
                  style={{ display: this.state.displayCarroVacio }}
                >
                  <h2 className="p-5 text-center">
                    Tienes una transacción pendiente o en espera de respuesta de
                    la pasarela de pagos.
                  </h2>
                </FormGroup>
                <FormGroup
                  row
                  style={{ display: this.state.displayCarroActivo }}
                >
                  <Col md="9" lg="8" sm="12">
                    <Card>
                      <CardBody>
                        <SummaryTable
                          data={this.state.datosCompraLaravel}
                          //data={this.props.detallesCarro}
                          delete={this.eliminarCita}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="3" lg="4" sm="12">
                    <Card>
                      <CardBody className="p-4">
                        <h4>Resumen payment</h4>
                        <br />
                        <table className="table">
                          <tbody>
                            <tr>
                              <td colSpan="2">
                                <strong>Referencia</strong>
                                <div>{this.state.referenceCode}</div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Items</strong>
                              </td>
                              <td className="text-right">
                                {this.state.numeroItems}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Subtotal</strong>
                              </td>
                              <td className="text-right">
                                {" "}
                                <NumberFormat
                                  value={this.state.valor}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </td>
                            </tr>
                            <tr className="pb-5">
                              <td>
                                <strong>Moneda</strong>
                              </td>
                              <td className="text-right">
                                <strong>{this.state.currency}</strong>
                              </td>
                            </tr>
                            <tr style={{ borderTop: "1px solid #184e7a" }}>
                              <td>
                                <h4>Total</h4>
                              </td>
                              <td className="text-right">
                                <h5>
                                  <NumberFormat
                                    value={this.state.valor}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                </h5>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        <form
                          method="post"
                          action={this.state.url}
                          onSubmit={this.cambiaEstado}
                        >
                          <input
                            name="merchantId"
                            type="hidden"
                            value={this.state.merchantId}
                          />
                          <input
                            name="accountId"
                            type="hidden"
                            value={this.state.accountId}
                          />
                          <input
                            name="description"
                            type="hidden"
                            value="Pago en DoctorHoy"
                          />
                          <input
                            name="referenceCode"
                            type="hidden"
                            value={this.state.referenceCode}
                          />
                          <input
                            name="amount"
                            type="hidden"
                            value={this.state.amount}
                          />
                          <input name="tax" type="hidden" value="0" />
                          <input name="taxReturnBase" type="hidden" value="0" />
                          <input
                            name="currency"
                            type="hidden"
                            value={this.state.currency}
                          />
                          <input
                            name="signature"
                            type="hidden"
                            value={this.state.signature}
                          />
                          <input name="test" type="hidden" value="0" />
                          {/* <input name="buyerEmail" type="hidden" value="test@test.com" /> */}
                          <input name="lng" type="hidden" value="es" />
                          <input
                            name="confirmationUrl"
                            type="hidden"
                            value={`${payuConfirmation}/doctor/response`}
                          />
                          <input
                            name="responseUrl"
                            type="hidden"
                            value={payuResponse}
                          />
                          <FormGroup row>
                            <Col
                              md="12"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <input
                                name="terminosAceptados"
                                type="checkbox"
                                className="form-control mr-2"
                                style={{ width: "20px", height: "20px" }}
                                checked={this.state.terminosAceptados}
                                onClick={this.handleChangeCheckBox}
                              />
                              <a
                                href={SummaryTermsConditions}
                                target={"_blank"}
                                style={{
                                  cursor: "pointer",
                                  color: "blue",
                                  textDecoration: "underline",
                                }}
                              >
                                Aceptar Términos y condiciones
                              </a>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col
                              md="12"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <input
                                name="consInformado"
                                type="checkbox"
                                className="form-control mr-2"
                                style={{ width: "20px", height: "20px" }}
                                checked={this.state.consInformado}
                                onClick={this.handleChangeCheckBoxConsInformado}
                              />
                              <PDFDownloadLink
                                document={
                                  <ConsInformado
                                    userFullName={userFullName}
                                    DHusername={DHusername}
                                  />
                                }
                                fileName="ConsentimientoInformadoVideoConsulta.pdf"
                              >
                                <span
                                  style={{
                                    cursor: "pointer",
                                    color: "blue",
                                    textDecoration: "underline",
                                  }}
                                >
                                  He leído y acepto el consentimiento informado
                                </span>
                              </PDFDownloadLink>
                            </Col>
                          </FormGroup>
                          <Button
                            color="primary"
                            size="lg"
                            // disabled={!this.state.activarPago}
                            className="text-center"
                            block
                          >
                            Pagar
                          </Button>
                        </form>
                        <br />
                        <br /> <br />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12" lg="12" sm="12">
                    <Card>
                      <CardBody className="p-4">
                        <div>
                          <span className="span-warning">Recuerde</span>
                        </div>
                        <ul className="list-bullets">
                          <li className="mb-2 mt-2">
                            Si elige{" "}
                            <span className="span-warning">
                              Consulta Programada para la fecha deseada
                            </span>{" "}
                            puedes pagar en efectivo (Efecty, Baloto o Bancos),
                            tiene hasta 45 minutos <br />
                            para realizar el pago. de lo contrario la cita
                            expira y debe tomar una nuevamente una cita
                          </li>
                          <li className="mb-2 mt-2">
                            Recuerde, tiene un plazo de 12 horas para
                            reprogramar la cita. si no lo realiza, esta cita
                            queda paga al medico y debe volver a solicitar una
                            nueva cita.
                          </li>
                          <li>
                            Si elige{" "}
                            <span className="span-warning">
                              Solicita una cita médica para hoy
                            </span>{" "}
                            el pago debe realizarse por pse o tarjeta de
                            crédito, tiene hasta 30 minutos para realizar el
                            pago,
                            <br />
                            de lo contrario la cita expira y debe tomar una cita
                            nuevamente
                          </li>
                        </ul>
                      </CardBody>
                    </Card>
                  </Col>
                </FormGroup>
              </div>
            </div>
          </main>
          <Footer />
          <div id="toTop" />
        </div>
        <RefreshToken onRef={(ref) => (this.RefreshToken = ref)} />
      </div>
    );
  }
}

Summary.propTypes = {
  detallesCarro: PropTypes.array,
  GetDatosCompra: PropTypes.func,
  GetDetalleCarroCompras: PropTypes.func,
  GetDetalleOrdenDeComprasLaravel: PropTypes.func,
  deleteOrdenCompraLaravel: PropTypes.func,
};

Summary.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  detallesCarro: state.doctor.detalleCarroCompras,
});

export default connect(mapStateToProps, {
  GetDatosCompra,
  GetDetalleCarroCompras,
  GetDetalleOrdenDeComprasLaravel,
  deleteOrdenCompraLaravel,
})(Summary);
