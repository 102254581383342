/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import * as yup from "yup";

const schema = yup.object().shape({
  professional_card: yup.string().required("Numero tarjeta es requerido"),
  // simultaneous_appointments: yup.number().required("Documento es requerido"),
  review: yup.string().required("Perfil es requerido"),
  gender: yup.object().required("Genero es requerido"),
  email: yup
    .string()
    .email("No es un formato valido")
    .required("Email es requerido"),
  cel_phones: yup.string().required("Celular es requerido"),
  // race: yup
  //   .object()
  // .required('Etnia es requerida'),

  birth_country: yup
    .object()
    .required("Pais de nacimiento es requerida")
    .nullable(),
  birth_department: yup
    .object()
    .required("Departamento de nacimiento es requerida")
    .nullable(),
  birth_city: yup
    .object()
    .required("Ciudad de nacimiento es requerida")
    .nullable(),

  resident_country: yup
    .object()
    .required("Pais de residencia es requerida")
    .nullable(),
  resident_department: yup
    .object()
    .required("Departamento de residencia es requerida")
    .nullable(),
  resident_city: yup
    .object()
    .required("Ciudad de nacimiento es requerida")
    .nullable(),
});

// export const parseInfo = (data) => {
//   const formData = new FormData();
//   Object.entries(data).forEach(([key, value]) => {
//     if (key.startsWith("social_network_")) {
//       formData.append(`social_network_id[${key.split("d")[1]}]`, value);
//     } else {
//       formData.append(key, value);
//     }
//   });
//   return formData;
// };

export const parseInfo = (data) => {
  const parsedData = {};

  for (const [key, value] of Object.entries(data)) {
    if (key.startsWith("social_network_")) {
      const id = key.split("d")[1];
      const newKey = `social_network_id[${id}]`;
      parsedData[newKey] = value;
    } else {
      parsedData[key] = value;
    }
  }

  console.log("PARSED DATA", parsedData);
  return parsedData;
};

export default schema;
