/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */
import { createBrowserHistory } from 'history';
import axios from "axios";

/**
 * @author Daniel Felipe Lucumi Marin
 * @file Http.js
 * @description Request handler
 */

axios.interceptors.request.use((config) => {
  if (localStorage.token) {
    config.headers["authorization"] = `Bearer ${localStorage.token.replace(
      /['"]+/g,
      ""
    )}`;
    //config.headers["Content-Type"] = 'application/json; charset=UTF-8';
  }
  return config;
});

axios.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    if (error?.response?.status === 401) {
      console.log("Error 401", error.response.status);
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use( (response) => {
  if(response?.data){
    const data = response.data;
    if(data.code == 1001 || data.code == 2001){
      if(data.message == 'Token vencido'){
        //this.context.router.history.push("/login");
        createBrowserHistory().push('/login');
        window.location.reload();
      }
    }
  }
  return response;
},
(error) => {
  if (error?.response?.status === 401) {
    console.log("Error 401 2", error.response.status);
  }
  return Promise.reject(error);
}
);

export default axios;
