/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Alert, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  createNewUser,
  setApiPatientStore,
} from "../../redux/actions/userAction";
import { createUser } from "../../redux/actions/types";
import { login } from "../../redux/actions/authAction";
import { getUserInfo, loginLaravel } from "../../redux/actions/userAction";
import { userInfo } from "../../redux/actions/types";
import BarraSuperior from "../Generic/BarraSuperior";
import iconDH from "../../assets/img/icon-dh.png";
import { sendSMS } from "../../Components/MessageBirdApi/MessageBirdApi";
import {
  getDocumentType,
  getCountries,
  getCities,
  getDepartments,
} from "../../redux/actions/GenericAction";
import {
  DocumetTypes,
  getCountryUrl,
  departments,
  cities,
} from "../../redux/actions/types";

// Components
import Footer from "../../Components/Templates/Footer";
import RegisterForm from "../../Components/Forms/PersonRegister";
import Modal from "../../Components/Modal";

// Constants
import { PatientTermsConditions } from "../../enviroment";
import { NewPersonRegisterContainer } from "./newPersonRegister.style";

// Styles
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

class NewPersonRegister extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalState: false,
      recaptcha: false,
      display: "",
      sendRequest: false,
    };

    this.toggle = this.toggle.bind(this);
    this.sendInfo = this.sendInfo.bind(this);
    this.recaptchaVerify = this.recaptchaVerify.bind(this);
    // this.onSelectCountry = this.onSelectCountry.bind(this);
    // this.onSelectDepartment = this.onSelectDepartment.bind(this);
  }

  recaptchaVerify(response) {
    if (response) {
      this.setState({ recaptcha: true });
    }
  }

  sendInfo(data) {
    delete data.Pass2Paciente;
    data.celular = data.phone;
    let user = Object.assign({}, data, {
      action_type: 1,
      profile: {
        profile_id: "50613943-3B2C-4B92-BDAC-1BE82F973585",
      },
    });

    this.setState({ sendRequest: true });
    // this.props.createNewUser(createUser, user).then(resp => {
    this.props.setApiPatientStore(user).then((resp) => {
      if (resp.code === 0) {
        this.setState({
          display: (
            <Alert color="success">
              Hemos enviado a su correo la información necesaria para completar
              el registro. Sera redireccionado al inicio.
            </Alert>
          ),
        });

        const body = {
          username: user.name,
          cellphone: user.celular,
          msg: `Hola, ${user.name} Bienvenido a DoctorHoy 🎉
          . Hemos habilitado tu cuenta. Actualiza tus datos antes de tu primera cita para mayor agilidad y comodidad. https://www.doctorhoy.com `,
        };

        sendSMS(body, "/send-sms")
          .then(() => {
            console.log("mensaje enviado");
          })
          .catch((errorMsg) => {
            console.log("Message bird Error", errorMsg);
          });

        // Aqui mando a hacer el login
        setTimeout(
          function () {
            const datalogin = {
              username: data.username,
              password: data.password,
              name: "chrome",
              user_type: 1,
            };
            this.props
              .loginLaravel("api/login", datalogin)
              .then((reslogin) => {
                console.log("entro a peticion login", datalogin);
                // this.props.login(data, "token").then(() => {
                if (reslogin.access_token) {
                  localStorage.setItem("token", reslogin.access_token);
                  // if (this.props.isAuthenticated) {
                  this.props.getUserInfo(userInfo).then((res) => {
                    if (!res) {
                      this.setState({ sendRequest: false });
                      return;
                    }
                    const userInfo = res.data;
                    if (
                      userInfo &&
                      userInfo.profile &&
                      userInfo.profile.profile_name &&
                      userInfo.profile.profile_name === "Paciente"
                    ) {
                      this.context.router.history.push("/Dashboard");
                    }
                  });
                }

                if (this.props.messageError && this.props.messageError !== "") {
                  this.setState({ sendRequest: false });
                  Swal.fire({
                    title: this.props.messageError,
                    icon: `error`,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                  return;
                }
              })
              .catch((e) => {
                this.setState({ sendRequest: false });
                if (e.response.status == 401) {
                  Swal.fire({
                    title: "Usuario o contraseña incorrectos",
                    icon: `error`,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                } else {
                  Swal.fire({
                    title: "Error interno",
                    icon: `error`,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                }

                console.log("aqui error", e.response.status);
              });
          }.bind(this),
          1000
        );
      } else {
        this.setState({ sendRequest: false });
        Swal.fire({
          title: resp.message,
          icon: `error`,
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }
    });
  }

  toggle() {
    const { modalState } = this.state;
    this.setState({ modalState: !modalState });
  }

  goBack() {
    window.history.back();
  }

  componentDidMount() {
    this.props.getDocumentType(DocumetTypes);
  }

  render() {
    const { modalState } = this.state;

    return (
      <>
        {/* <BarraSuperior /> */}

        <NewPersonRegisterContainer>
          <div>
            <h1>¡Bienvenido a DoctorHoy!</h1>
            <h3>Regístrate y consigue tu cita médica hoy mismo</h3>
            <RegisterForm
              openTerms={this.toggle}
              submitForm={this.sendInfo}
              loading={this.state.sendRequest}
              {...this.props}
              allProps={this.props}
              allStates={this.state}
              goBack={this.goBack}
              // eventOnSelectCountry={this.onSelectCountry}
              // eventOnSelectDepartment={this.onSelectDepartment}
            />
          </div>
        </NewPersonRegisterContainer>

        {/* <Footer /> */}
        <Modal
          title="Términos y Condiciones de Uso"
          status={modalState}
          toggle={this.toggle}
        >
          <iframe
            src={PatientTermsConditions}
            title="Terminos y condiciones"
            style={{ width: "100%", height: 600 }}
          >
            Presss me: <a href={PatientTermsConditions}>Descargar PDF</a>
          </iframe>
        </Modal>
        <ToastContainer />
      </>
    );
  }
}

NewPersonRegister.propTypes = {
  createNewUser: PropTypes.func,
  setApiPatientStore: PropTypes.func,
  login: PropTypes.func.isRequired,
  getDocumentType: PropTypes.func,
  messageError: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  documentTypes: PropTypes.func.isRequired,
  loginLaravel: PropTypes.func,
};

NewPersonRegister.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userInfo: state.user.userInfo,
  documentTypes: state.generic.documentTypes,
  countries: state.generic.countries,
  departments: state.generic.departments,
  cities: state.generic.cities,
});

export default connect(mapStateToProps, {
  createNewUser,
  setApiPatientStore,
  login,
  getUserInfo,
  getDocumentType,
  getCountries,
  getDepartments,
  getCities,
  loginLaravel,
})(NewPersonRegister);
